import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './EquilibriumGuide.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { ButtonType1 } from '../../components/Button/ButtonType1';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/EquilibriumGuide/74a.png';
import page2 from '../../assets/images/EquilibriumGuide/74a_배.png';
import page3 from '../../assets/images/EquilibriumGuide/74b.png';
import page4 from '../../assets/images/EquilibriumGuide/74b_배.png';
import page5 from '../../assets/images/EquilibriumGuide/84a.png';
import page6 from '../../assets/images/EquilibriumGuide/84a_배.png';
import page7 from '../../assets/images/EquilibriumGuide/84b.png';
import page8 from '../../assets/images/EquilibriumGuide/84b_배.png';
import page9 from '../../assets/images/EquilibriumGuide/84c.png';
import page10 from '../../assets/images/EquilibriumGuide/84c_배.png';
import page11 from '../../assets/images/EquilibriumGuide/112a.png';
import page12 from '../../assets/images/EquilibriumGuide/112b.png';
// import { TitleBox } from '../../components/TitleBox/TitleBox';

// 평형안내
const EquilibriumGuide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isClicked, setIsClicked] = useState("전체");

  return (
    <>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        {/* <TitleBox title={"평형안내"} /> */}

        <div>
          <div className={styles.buttonBoxTitle}>평형 선택</div>
          <div className={styles.buttonBox}>
            <ButtonType1 title="전체" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="74A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="74B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84C" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="112A, B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
          </div>
        </div>

        <div className={styles.imageBox}>
          <ImageBoxContainer type={isClicked} />
        </div>

        <Footer />
        <FixedButton />
        <IpBox />
      </div>
    </>
  );
};

export default EquilibriumGuide;


const ImageBoxContainer = ({ type }) => {
  if (type === "74A") {
    return (
      <>
        <img className={styles.image} src={page1} alt="74A" />
        <img className={styles.image} src={page2} alt="74A" />
      </>
    )
  } else if (type === "74B") {
    return (
      <>
        <img className={styles.image} src={page3} alt="74B" />
        <img className={styles.image} src={page4} alt="74B" />
      </>
    )
  } else if (type === "84A") {
    return (
      <>
        <img className={styles.image} src={page5} alt="84A" />
        <img className={styles.image} src={page6} alt="84A" />
      </>
    )
  } else if (type === "84B") {
    return (
      <>
        <img className={styles.image} src={page7} alt="84B" />
        <img className={styles.image} src={page8} alt="84B" />
      </>
    )
  } else if (type === "84C") {
    return (
      <>
        <img className={styles.image} src={page9} alt="84C" />
        <img className={styles.image} src={page10} alt="84C" />
      </>
    )
  } else if (type === "112A, B") {
    return (
      <>
        <img className={styles.image} src={page11} alt="112A" />
        <img className={styles.image} src={page12} alt="112B" />
      </>
    )
  } else {
    return (
      <>
        <img className={styles.image} src={page1} alt="74A" />
        <img className={styles.image} src={page2} alt="74A" />
        <img className={styles.image} src={page3} alt="74B" />
        <img className={styles.image} src={page4} alt="74B" />
        <img className={styles.image} src={page5} alt="84A" />
        <img className={styles.image} src={page6} alt="84A" />
        <img className={styles.image} src={page7} alt="84B" />
        <img className={styles.image} src={page8} alt="84B" />
        <img className={styles.image} src={page9} alt="84C" />
        <img className={styles.image} src={page10} alt="84C" />
        <img className={styles.image} src={page11} alt="112A" />
        <img className={styles.image} src={page12} alt="112B" />
      </>
    )
  }
}
