import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Premium.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page0 from '../../assets/images/Premium/page0.jpg';
import page1 from '../../assets/images/Premium/page1.jpg';
import page2 from '../../assets/images/Premium/page2.png';
import page3 from '../../assets/images/Premium/page3.png';
import page4 from '../../assets/images/Premium/page4.png';
import page5 from '../../assets/images/Premium/page5.jpg';
// import { TitleBox } from '../../components/TitleBox/TitleBox';

// 프리미엄
const Premium = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        {/* <TitleBox title={"프리미엄"} /> */}
        <div className={styles.imageBox}>
          <img className={styles.image} src={page0} alt="posco 평택 푸르지오 센터파인" />
          <img className={styles.image} src={page5} alt="posco 평택 푸르지오 센터파인" />
          <img className={styles.image} src={page1} alt="posco 평택 푸르지오 센터파인" />
          <img className={styles.image} src={page2} alt="posco 평택 푸르지오 센터파인" />
          <img className={styles.image} src={page3} alt="posco 평택 푸르지오 센터파인" />
          <img className={styles.image} src={page4} alt="posco 평택 푸르지오 센터파인" />
        </div>
        <Footer />
        <FixedButton />
        <IpBox />
      </div>
    </>
  );
};

export default Premium;
