import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState } from 'recoil';

import styles from './Main.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
// import { Modal } from '../../components/Modal/Modal'
import { IpBox } from '../../components/IpBox/IpBox';
import { MainMenuBox } from '../../components/MainMenuBox/MainMenuBox';
import { fetchData, getData } from '../../api/instance';
import { ipState } from '../../recoil/atom';
import mainImage from '../../assets/images/mainImage.png';
import page1 from '../../assets/images/Main/page1.png';
import page2 from '../../assets/images/Main/page2.png';
import page3 from '../../assets/images/Main/page3.png';
import page4 from '../../assets/images/Main/page4.png';
import page5 from '../../assets/images/Main/page5.png';
import page6 from '../../assets/images/Main/page6.png';
import page7 from '../../assets/images/Main/page7.png';


// HOME
const Main = () => {
  // const form = useRef();
  // const [clientName, setClientName] = useState("");
  // const [clientPhone, setClientPhone] = useState("");
  // const [isButton, setIsButton] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const [ip, setIp] = useState('');
  const [ipData, setIpData] = useRecoilState(ipState);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        const result = await fetchData();
        setIp(result);
      } catch (error) {
        // 에러 처리
      }
    };

    fetchDataFromBackend();
  }, []);

  useEffect(() => {
    const getIpDataFromBackend = async (ipData) => {
      try {
        const result = await getData(ipData);
        setIpData(result);
      } catch (error) {
        // 에러 처리
      }
    };

    if (ip !== '') getIpDataFromBackend(ip);
  }, [ip]);

  //총 합 구하기
  useEffect(() => {
    if (ipData && ipData.access && ipData.access.length > 0 && !ipData.accessTotal) {
      const array = ipData.access;
      var total = 0;
      for (var i = 0; i < array.length; i++) {
        total = total + array[i].accessNumber;
      }
      setIpData((prev) => ({ ...prev, accessToday: array[array.length - 1].accessNumber, accessTotal: total }));
      setIsModal(!isModal);
    }
  }, [ipData, setIpData]);

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   emailjs.sendForm("service_qarf9hc", "template_z448lio", form.current, "uRoWUSMp8hluJZG1P")
  //     .then(
  //       () => {
  //         alert("전송되었습니다.");
  //       },
  //       (error) => {
  //         console.log(error);
  //         alert("전송을 실패했습니다.");
  //       }
  //     );
  // };

  // const [clientName, setClientName] = useState("");
  // const [clientPhoneFirst, setClientPhoneFirst] = useState("010");
  // const [clientPhoneMiddle, setClientPhoneMiddle] = useState("");
  // const [clientPhoneList, setClientPhoneList] = useState("");
  // const [client, setClient] = useState({ name: "", phoneNumber: "" });

  // const booking = () => {
  //   const newInfor = client;
  //   newInfor.name = clientName;
  //   newInfor.phoneNumber = `${clientPhoneFirst}-${clientPhoneMiddle}-${clientPhoneList}`
  //   setClient(newInfor);
  //   console.log(client)
  // }

  // useEffect(() => {
  //   if (clientName !== "" && clientPhone.length >= 11) setIsButton(false);
  //   else setIsButton(true);
  // }, [clientName, clientPhone])


  // const handleInputChange = (event) => {
  //   let inputValue = event.target.value;
  //   // 숫자 이외의 값 제거
  //   inputValue = inputValue.replace(/[^0-9-]/g, "");
  //   // 입력 필드에 숫자만 설정
  //   event.target.value = inputValue;
  // }

  // // 방문 횟수를 가져오는 함수 정의
  // function getVisitCount() {
  //   var visitCount = localStorage.getItem("visitCount");
  //   if (!visitCount) {
  //     visitCount = 0;
  //   }
  //   return parseInt(visitCount);
  // }

  // // 방문 횟수를 증가시키는 함수 정의
  // function increaseVisitCount() {
  //   var visitCount = getVisitCount();
  //   visitCount++;
  //   localStorage.setItem("visitCount", visitCount);
  //   return visitCount;
  // }

  // // 사용자에게 방문 횟수를 알리는 함수 정의
  // function showVisitCount() {
  //   var visitCount = getVisitCount();
  //   visitCount = increaseVisitCount();
  //   alert("총 " + visitCount + "번 방문하셨습니다. 방문 로그와 횟수는 실시간 기록됩니다.");
  // }

  // 일시에 Ip가 본 사이트에 접속한 것이 확인되었습니다.
  return (
    <div>
      <Header pathName={currentPath} />
      <div className={styles.container}>
        <img className={styles.mainImage} src={mainImage} alt="main" />

        <div className={styles.page}>
          <img src={page1} alt="1" />
          <img src={page2} alt="2" />
          <img src={page3} alt="3" />
          <img src={page4} alt="4" />
          <img src={page5} alt="5" />
          <img src={page6} alt="6" />
          <img src={page7} alt="7" />
        </div>

        <MainMenuBox type={isMobile} />

        {/* <div className={styles.bookingContainer}>
          <div className={styles.bookingCase}>
            <div>빠른 상담 예약</div>
            <form className={styles.bookingBox} ref={form} onSubmit={sendEmail}>
              <input className={styles.input} type="text" name="from_name" placeholder=" 홍길동" onChange={(e) => setClientName(e.target.value)} />
              <input className={styles.input} type="tel" name="phone" placeholder="010-1234-5678" maxlength='13' onInput={handleInputChange} onChange={(e) => setClientPhone(e.target.value)} />
              <input className={[isButton ? styles.disabledButton : styles.button]} type="submit" value="예약 신청 하기" onClick={sendEmail} disabled={isButton} />
            </form>
          </div>

          <div className={styles.bookingCase}>
            <div>네이버 폼 예약</div>
            <a href='https://naver.me/FWfnlWCx' className={styles.bookingButton} target='_blank'>
              <TbHandClick color='#FFFFFF' size={25} />
              <div>빠른 상담 예약</div>
            </a>
          </div>
          
          <div className={styles.bookingBox}>
            <input className={styles.inputName} placeholder=' 성함' onChange={(e) => setClientName(e.target.value)} />
            <select onChange={(e) => setClientPhoneFirst(e.target.value)}>
              <option value="010">010</option>
              <option value="031">031</option>
              <option value="011">011</option>
              <option value="016">016</option>
              <option value="017">017</option>
              <option value="018">018</option>
              <option value="019">019</option>
            </select>
            <input className={styles.inputPhone} placeholder='1234' maxlength='4' onInput={handleInputChange} onChange={(e) => setClientPhoneMiddle(e.target.value)} />
            <input className={styles.inputPhone} placeholder='5678' maxlength='4' onInput={handleInputChange} onChange={(e) => setClientPhoneList(e.target.value)} />
            <button className={[isButton ? styles.disabledButton : ""]} onClick={booking} disabled={isButton}>예약 신청하기</button>
          </div>

        </div> */}

        <Footer />
        <FixedButton />
        <IpBox />
      </div>
      {/* {isModal && <Modal isClose={(e) => setIsModal(!isModal)} />} */}
    </div>
  );
};

export default Main;
