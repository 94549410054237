import axios from 'axios';

const API_BASE_URL = "https://port-0-backend-2scwz2alu86eppu.sel5.cloudtype.app"; // 백엔드 서버 주소
// const API_BASE_URL = 'http://localhost:8080'; // 백엔드 서버 주소

export const fetchData = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getData = async (ip) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/sendData`, {ipData: ip, idData: 2});
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};